import React from 'react';
import rehypeReact from 'rehype-react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Image from 'components/image';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { lfsimage: Image },
}).Compiler;

export default function Contact({ data }) {
  const contactContent = data.contactContent;
  return (
    <Layout>
      <h2>{contactContent.frontmatter.title}</h2>
      <div>{renderAst(contactContent.htmlAst)}</div>
    </Layout>
  );
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query Contact {
    contactContent: markdownRemark(
      fields: { slug: { eq: "/pages/contact/" } }
    ) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;
